<router>
{
    name: 'Analytics Settings',
}
</router>

<template lang="pug">
v-container(light)
    v-row
        v-col(cols="12")
            SystemsSettings(
                :systems="localSystems",
                :customerId="customerId",
                :uom="uom",
                @update:systems="handleUpdateSystems",
                @save:systems="handleSaveSystems",
                @refresh="fetchConfig",
                @download:client="downloadingClient",
            )


</template>

<script lang="js">

import mergeDeep from 'lodash/merge';
import SystemsSettings from '@/components/portal/analytics/Systems';

import { mapMutations, mapActions, mapState } from 'vuex';
import dashboard from '@/components/dashboard.vue';

export default {
    name: 'AnalyticsSettings',
    extends: dashboard,
    components: {
        SystemsSettings,
    },
    data () {
        return {
            isDownloadingClient: false,
            selectedSystem: null,
            localSystems: [],
            uom: []
        };
    },
    methods: {
        ...mapActions(['fetchAnalyticsConfig', 'saveAnalyticsConfig', 'fetchAnalyticsUnitsOfMeasure', 'updateAnalyticsConfig']),

        // Update the systems configuration in the store, be sure to always pass the entire systems array
        handleUpdateSystems (newSystems) {
            console.log('Updating systems:', newSystems);
            this.updateAnalyticsConfig(newSystems);
        },

        async fetchConfig () {
            await Promise.all([
                this.fetchAnalyticsConfig(),
                this.fetchAnalyticsUnitsOfMeasure()
            ]);
            this.localSystems = this.$store.state.analyticsConfig;
            this.uom = this.$store.state.analyticsUnitsOfMeasure;

        },

        // Save the systems configuration to server
        async handleSaveSystems (newSystems) {
            try {
                console.log('settings.vue >> Saving systems:', newSystems);
                await this.saveAnalyticsConfig(newSystems);
                await this.fetchConfig();
            } catch (error) {
                console.error('Error saving configuration', error);
            }
        },

        /**
         *
         * @param {object} categoryPayload
         * @param {object} categoryPayload.system_id - The system to save the categories for
          * @param {array} categoryPayload.categories - The categories to save
         */
        async handleSaveCategories (categoryPayload) {
            console.log('Saving categories:', categoryPayload);
            // find the system in the config
            const found = this.analyticsConfig.find(system => system.id === this.selectedSystem.id);
            if (!found) {
                console.error('Could not find system in config');
                return;
            }
            found.categories = categoryPayload.categories;

            this.setAnalyticsConfig(this.analyticsConfig);

        },

        openPoints (system) {
            this.$set(this, 'selectedSystem', system);
            this.$set(this, 'uom', this.$store.state.analyticsUnitsOfMeasure);
            this.$set(this, 'canViewPoints', true);
        },

        downloadingClient (bool) {
            console.log('settings >> Downloading client:', bool);
            this.isDownloadingClient = bool;
        }
    },
    watch: {
        customerId: {
            handler (newVal) {
                console.log('Customer ID changed:', newVal);
                this.fetchConfig();
                this.canViewPoints = false;
            },
            immediate: true
        }
    },
    computed: {
        ...mapState({
            analyticsConfig: state => {
                return state.analyticsConfig || [];
            },
            customerId: state => state.customerId
        }),
    },
    created () {
        this.$store.dispatch('connectSocket');
        this.fetchConfig();
    },
    mounted () {
        window.settings = this;
    }
};

</script>
