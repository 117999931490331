<template lang="pug">
v-container
    v-card(light)
        v-card-title Systems
        v-card-text
            v-row
                v-col(cols="12")
                    v-data-table(
                        :headers="headers"
                        :items="localSystems"
                        class="elevation-1"
                        item-key="id"
                        light
                    )

                        template(v-slot:item.system_name="{ item }")
                            span.align-center {{ item.system_name }}

                        template(v-slot:item.actions="{ item }")
                            v-btn(color="red" :disabled="isDownloading" @click="deleteSystem(item.id)").mr-3 Delete
                            v-btn(color="orange" :disabled="isDownloading" @click="openEditDialog(item)").mr-3 Edit
                            v-btn(color="#00ffff" :disabled="isDownloading" @click="openCategories(item)").mr-3 Configure
                            v-btn(color="brand" :disabled="isDownloading" @click="downloadClient(item)").white--text
                                | Download Client
                                template(v-if="isDownloading")
                                    v-progress-circular(indeterminate size="20")
            v-row(v-if="showCategories")
                v-col(cols="12" )
                    Categories(
                        :categories="selectedSystem?.categories",
                        :systemName="selectedSystem?.system_name",
                        :systemId="selectedSystem?.id",
                        :uom="uom",
                        @close:categories="showCategories = false",
                        @update:categories="handleUpdateCategories"
                    )
        v-card-actions
            //- TODO: Move to parent
            v-btn(color="green" :disabled="isDownloading" @click="saveAllSystems") Save All Systems
            v-btn(color="orange" :disabled="isDownloading || isAddSystemsDisabled" @click="addSystem") Add System
            v-btn(color="blue" :disabled="isDownloading" @click="refresh") Refresh
            v-spacer
            //- TODO: Move to parent
            span.no-changes-text No changes sent to server without clicking Save

    v-dialog(v-model="editDialog" max-width="600px")
        v-card(light)
            v-card-title
                v-row(align="center" justify="space-between")
                    span {{ dialogTitle }}
                    v-btn(icon @click="closeEditDialog")
                        v-icon close
            v-card-text
                v-form(ref="editForm", v-model="formValid")
                    v-row()
                        v-col(cols="12")
                            v-text-field(
                                v-model.trim="editedItem.system_name"
                                label="System Name"
                                :rules="[rules.required]"
                                outlined
                                dense
                                persistent-hint
                                color="brand"
                            )
                    v-row()
                        v-col(cols="12")
                            v-text-field(
                                v-model.trim="editedItem.api_key"
                                :label="softwareType"
                                :rules="[rules.required]"
                                outlined
                                dense
                                persistent-hint
                                color="brand"
                            )
                    v-row()
                        v-col(cols="6")
                            v-text-field(
                                v-model.number="editedItem.latitude"
                                label="Latitude"
                                :rules="[rules.isNumber, rules.latitude]"
                                outlined
                                dense
                                type="number"
                                step="0.0001"
                                persistent-hint
                                color="brand"
                            )
                        v-col(cols="6")
                            v-text-field(
                                v-model.number="editedItem.longitude"
                                label="Longitude"
                                :rules="[rules.isNumber, rules.longitude]"
                                outlined
                                dense
                                type="number"
                                step="0.0001"
                                persistent-hint
                                color="brand"
                            )
                    v-row()
                        v-col(cols="12")
                            v-select(
                                v-model="editedItem.time_zone"
                                :items="timeZones"
                                label="Time Zone"
                                :rules="[rules.required]"
                                outlined
                                dense
                                persistent-hint
                                color="brand"
                            )
                    v-row()
                        v-col(cols="6")
                            v-text-field(
                                v-model.number="editedItem.flow_production_max"
                                label="Production Max"
                                :rules="[rules.isNumber, rules.productionMax]"
                                outlined
                                dense
                                type="number"
                                step="1"
                                persistent-hint
                                color="brand"
                            )
                        v-col(cols="6")
                            v-select(
                                v-model="editedItem.flow_production_uom"
                                :items="productionUomOptions"
                                item-text="name"
                                item-value="value"
                                label="Production UOM"
                                outlined
                                dense
                                persistent-hint
                                color="brand"
                            )
                    v-row()
                        v-col(cols="12" sm="6" md="4")
                            v-text-field(
                                v-model.number="editedItem.flow_storage_max"
                                label="Storage Max"
                                :rules="[rules.isNumber, rules.storageMax, rules.storageMaxMin]"
                                outlined
                                dense
                                type="number"
                                step="1"
                                persistent-hint
                                color="brand"
                            )
                        v-col(cols="12" sm="6" md="4")
                            v-text-field(
                                v-model.number="editedItem.flow_storage_min"
                                label="Storage Min"
                                :rules="[rules.isNumber, rules.storageMin, rules.storageMinMax]"
                                outlined
                                dense
                                type="number"
                                step="1"
                                persistent-hint
                                color="brand"
                            )
                        v-col(cols="12" sm="6" md="4")
                            v-select(
                                v-model="editedItem.flow_storage_uom"
                                :items="storageUomOptions"
                                item-text="name"
                                item-value="value"
                                label="Storage UOM"
                                outlined
                                dense
                                persistent-hint
                                color="brand"
                            )
                    v-row()
                        v-col(cols="6")
                            v-text-field(
                                v-model="editedItem.flow_plant_start_time"
                                label="Plant Start Time (HH:mm)"
                                :rules="[rules.required, rules.plantStartTime]"
                                outlined
                                dense
                                persistent-hint
                                color="brand"
                                class="no-clipping"
                            )
            v-card-actions
                v-spacer
                v-btn(color="green" :disabled="!formValid" @click="saveEdit") Save
                v-btn(color="red" @click="closeEditDialog") Cancel


</template>

<script>

import {nanoid} from 'nanoid';
import mergeDeep from 'lodash/merge';
import Categories from './Categories.vue';

export default {
    name: 'SystemsSettings',
    emits: ['update:systems', 'refresh', 'save:systems', 'download:client', 'create:model'],
    components: {
        Categories
    },
    props: {
        customerId: {
            type: Number,
            required: true
        },
        systems: {
            type: Array,
            default: () => []
        },
        uom: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            isDownloading: false,
            selectedSystem: null,
            showCategories: false,
            localUom: [],
            headers: [
                { text: 'Name', value: 'system_name' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            rules: {
                required: (value) => !!value || 'Required.',
                isNumber: (value) => !isNaN(parseFloat(value)) || 'Must be a number.',
                latitude: (value) => value >= -90 && value <= 90 || 'Must be between -90 and 90.',
                longitude: (value) => value >= -180 && value <= 180 || 'Must be between -180 and 180.',
                productionMax: (value) => value >= 0 && value <= 100 || 'Must be between 0 and 100.',
                storageMax: (value) => value >= 0 && value <= 100 || 'Must be between 0 and 100.',
                storageMin: (value) => value >= 0 && value <= 100 || 'Must be between 0 and 100.',
                plantStartTime: (value) => /^([01]?\d|2[0-3]):[0-5]\d$/.test(value) || 'Must be a valid time in HH:mm format.',
                storageMaxMin: (value) => value >= this.editedItem.flow_storage_min || 'Storage Max must be greater than Storage Min.',
                storageMinMax: (value) => value <= this.editedItem.flow_storage_max || 'Storage Min must be less than Storage Max.',
            },
            timeZones: [
                'America/New_York',    // Eastern Time
                'America/Detroit',     // Eastern Time
                'America/Kentucky/Louisville', // Eastern Time
                'America/Kentucky/Monticello', // Eastern Time
                'America/Indiana/Indianapolis', // Eastern Time
                'America/Indiana/Vincennes', // Eastern Time
                'America/Indiana/Winamac', // Eastern Time
                'America/Indiana/Marengo', // Eastern Time
                'America/Indiana/Petersburg', // Eastern Time
                'America/Indiana/Vevay', // Eastern Time
                'America/Chicago',     // Central Time
                'America/Indiana/Tell_City', // Central Time
                'America/Indiana/Knox', // Central Time
                'America/Menominee', // Central Time
                'America/North_Dakota/Center', // Central Time
                'America/North_Dakota/New_Salem', // Central Time
                'America/North_Dakota/Beulah', // Central Time
                'America/Denver',      // Mountain Time
                'America/Boise',       // Mountain Time
                'America/Phoenix',     // Mountain Standard Time (no DST)
                'America/Los_Angeles', // Pacific Time
                'America/Anchorage',   // Alaska Time
                'America/Juneau',      // Alaska Time
                'America/Sitka',       // Alaska Time
                'America/Metlakatla',  // Alaska Time
                'America/Yakutat',     // Alaska Time
                'America/Nome',        // Alaska Time
                'America/Adak'         // Hawaii-Aleutian Time
            ],
            // populate from API
            storageUomOptions: [
                { name: 'Gallons', value: 'G' },
                { name: 'Million Gallons', value: 'MG' },
            ],
            productionUomOptions: [
                { name: 'Gallons per Day', value: 'GPD' },
                { name: 'Million Gallons per Day', value: 'MGPD' },
            ],
            localSystems: mergeDeep([], this.systems),
            defaultItem: {
                id: null,
                system_name: '',
                latitude: null,
                longitude: null,
                flow_production_max: null,
                flow_production_uom: 'GPD',
                flow_storage_max: null,
                flow_storage_min: null,
                flow_storage_uom: 'G',
                flow_plant_start_time: '12:00',
                customerId: this.customerId,
                api_key: '',
                categories: [],
                points: [],
                time_zone: ''
            },
            editDialog: false,
            editedItem: { ...this.defaultItem },
            newItem: null,
            formValid: false,
        };
    },
    computed: {
        dialogTitle () {
            return this.newItem ? 'Add System' : 'Edit System';
        },
        isAddSystemsDisabled () {
            return this.localSystems.length > 0;
        },
        softwareType () {
            const software = this.$store.state.customerData.customersConfig.systemSoftware === 'Other' ?  'server' : this.$store.state.customerData.customersConfig.systemSoftware;
            return `API Key for talking to customer's ${software}`;
        }
    },
    methods: {
        handleUpdateCategories (categories) {
            if (!Array.isArray(categories)) {
                console.error('Invalid categories:', categories);
                return;
            }

            console.log('Updating categories:', categories);
            // find the system in the config
            // this.localSystems.findIndex(cat => cat.id === payload.category_id);
            const found = this.localSystems.find(system => system.id === this.selectedSystem.id);
            if (!found) {
                console.warn('Could not find system in config');
                return;
            }
            found.categories = categories;
            const clonedLocalSystems = mergeDeep([], this.localSystems);

            // update the store
            this.$emit('update:systems', clonedLocalSystems);
        },

        openCategories (system) {
            console.log('Configuring points for:', system.id);
            this.selectedSystem = system;
            this.showCategories = true;
        },

        refresh () {
            this.$emit('refresh');
        },

        // adds to local data only
        addSystem () {
            this.newItem = {
                ...this.defaultItem,
                id: nanoid(10),
                customerId: this.customerId
            };
            this.openEditDialog(this.newItem);
        },

        // saves all systems
        saveAllSystems () {
            this.$emit('save:systems', this.localSystems);
        },

        // removes a local system
        deleteSystem (id) {
            this.$set(this, 'localSystems', this.localSystems.filter(system => system.id !== id));
        },

        openEditDialog (item) {
            this.editedItem = { ...item };
            if (item.id) {
                // if editing an existing item, set the start time to the correct format hh:mm
                this.editedItem.flow_plant_start_time = this.editedItem.flow_plant_start_time.split(':').slice(0, 2).join(':');
            }
            this.editDialog = true;
            this.$nextTick(() => {
                this.$refs.editForm.validate();
            });
        },
        closeEditDialog () {
            this.editDialog = false;
            this.editedItem = { ...this.defaultItem };
            this.newItem = null;
        },
        // save to localSystems
        saveEdit () {
            if (this.$refs.editForm.validate()) {
                if (this.newItem) {
                // append the new item to the localSystems array
                    this.$set(this.localSystems, this.localSystems.length, {...this.editedItem});
                } else {
                    const index = this.localSystems.findIndex(system => system.id === this.editedItem.id);
                    if (index !== -1) {
                        this.$set(this.localSystems, index, this.editedItem);
                    }
                }
                // do not commit to the store unless providing all systems
                this.closeEditDialog();
            }
        },

        downloadClient (item) {
            this.$emit('download:client', true);
            this.isDownloading = true;
            console.log('Downloading client for:', item.id);
            // Your download logic here
            // create a timeout to simulate download
            setTimeout(() => {
                console.log(' -- Download complete');
                this.isDownloading = false;
                this.$emit('download:client', false);
            }, 5000);
        }
    },
    watch: {
        systems: {
            handler (newSystems){
                this.localSystems = mergeDeep([], newSystems);
                this.selectedSystem = null;
                this.showCategories = false;
            },
            deep: true,
            immediate: true
        }
    },
    mounted () {
        console.log('Systems mounted:', this.systems);
        this.localSystems = mergeDeep([], this.systems);
    }
};
</script>

<style scoped>
.align-center {
    display: flex;
    align-items: center;
}
.no-changes-text {
    margin-left: 16px;
    color: gray;
}
.no-clipping .v-label {
    white-space: nowrap;
}
</style>
