<router>
{
    name: 'Units Of Measure',
}
</router>

<template lang="pug">
v-container
    v-row
        v-col
            Uom
    v-row
        v-col
            Factors

</template>

<script lang="js">
import Uom from '@/components/analytics/uom.vue';
import Factors from '@/components/analytics/factors.vue';

export default {
    meta: {
        role: 'analytics-uom'
    },
    components: {
        Uom,
        Factors
    },
    data () {
        return {};
    },
};
</script>
