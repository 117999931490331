<router>
{
    name: 'Tasks',
}
</router>
<template lang="pug">
.analytics__tasks
    v-container
        v-card.ml-8(light)
            v-card-title.brand.white--text.py-2 Tasks
            v-card-text
                v-data-table(
                    :headers="headers"
                    :items="tasks"
                )
                    template(v-slot:item.schedule="{ item }")
                        span(v-if="item.schedule === 'DAY'" ) Daily
                        span(v-else-if="item.schedule === 'WEEK'") Weekly
                        span(v-else-if="item.schedule === 'MONTH'") Monthly
                        span(v-else-if="item.schedule === 'EVENT'" style="font-weight:bold; color:red") Event
                        span(v-else-if="item.schedule === 'DEMAND'" style="font-weight:bold; color:blue") Demand
                        span(v-else) {{ item.schedule }}
                    template(v-slot:item.depends_on="{ item }")
                        span(v-if="item.depends_on") {{ getTaskName(item.depends_on) }}
                        span(v-else) -
                    template(v-slot:item.resource_limit="{ item }")
                        span(v-if="item.resource_limit") {{ item.resource_limit }} workers
                        span(v-else) unlimited
                    template(v-slot:top)
                        v-toolbar(flat)
                            v-spacer
                            v-dialog(
                                v-model='dialog'
                                max-width='900px'
                                light
                            )
                                template(v-slot:activator="{on, attrs}")
                                    v-btn.brand.white--text(
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                    ) New Task
                                v-card
                                    v-card-title.brand.white--text {{ formTitle }}
                                    v-card-text
                                        v-container
                                            v-form( ref="form" v-model="valid" )
                                                v-row
                                                    v-col(cols="12" sm="6" md="4")
                                                        v-text-field(
                                                            v-model="editedItem.task_name"
                                                            :rules="[rules.required, rules.minLength, rules.duplicate]"
                                                            :label="nameTitle"
                                                            required
                                                            color="brand"
                                                            :readonly="editedIndex > -1"
                                                        )
                                                    v-col(cols="12" sm="6" md="4")
                                                        v-select(
                                                            v-model="editedItem.schedule"
                                                            :items="scheduleOptions"
                                                            label="Schedule"
                                                            required
                                                            color="brand"
                                                        )
                                                    v-col(cols="4" sm="6" md="4")
                                                        v-tooltip(top)
                                                            template(v-slot:activator="{ on, attrs }")
                                                                v-text-field(
                                                                    v-model="editedItem.priority"
                                                                    :rules="[rules.required]"
                                                                    label="Priority"
                                                                    required
                                                                    type="number"
                                                                    min="1"
                                                                    max="100"
                                                                    color="brand"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                )
                                                            span Lower priority values take precedence over larger value
                                                v-row
                                                    v-col(cols="12" sm="6" md="4")
                                                        v-select(
                                                            v-model="editedItem.depends_on"
                                                            :items="tasks"
                                                            item-text="task_name"
                                                            item-value="task_id"
                                                            :rules="[rules.notSelf]"
                                                            label="Depends On"
                                                            color="brand"
                                                            clearable
                                                        )
                                                    v-col(cols="12" sm="6" md="4")
                                                        v-tooltip(top)
                                                            template(v-slot:activator="{ on, attrs }")
                                                                v-text-field(
                                                                    v-model="editedItem.resource_limit"
                                                                    label="Resource Limit"
                                                                    color="brand"
                                                                    :rules="[rules.isPosNumberOrEmpty]"
                                                                    clearable
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                )
                                                            span Number of workers that can be spawned at any given time. Leave empty for no limit.
                                                    v-col(cols="12" sm="6" md="4" )
                                                        template(v-if="editedItem.schedule === 'EVENT'")
                                                            v-tooltip(top)
                                                                template(v-slot:activator="{ on, attrs }")
                                                                    v-text-field(
                                                                        v-model="editedItem.event_name"
                                                                        label="Event Name"
                                                                        color="brand"
                                                                        clearable
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    )
                                                                span when emitted this task will be inserted with a status of <i>COMPLETED</i>
                                                        v-spacer(v-else)

                                                v-row
                                                    v-col(cols="12" sm="6" md="4")
                                                        v-text-field(
                                                            v-model="editedItem.worker_name"
                                                            label="Worker Name"
                                                            color="brand"
                                                            clearable
                                                        )
                                                v-row()
                                                    v-col
                                                        .text-h6 Notes
                                                        VueTrix.notesEditor(
                                                            v-model='editedItem.description',
                                                            placeholder='Notes'
                                                        )

                                    v-card-text
                                        p Workers may need to be created for each task.
                                    v-card-actions
                                        v-spacer
                                        v-btn.actions.orange.white--text(
                                            text
                                            @click="close"
                                        ) Cancel
                                        v-btn.actions.brand.white--text(
                                            text
                                            :disabled="!valid"
                                            @click="save"
                                        ) Save
                            v-dialog(
                                v-model='dialogDelete'
                                max-width='500px'
                                light
                            )
                                v-card
                                    v-card-title.brand.white--text Confirm Delete
                                    v-card-text
                                        span.text-h6 Are you sure you want to delete this Task?
                                    v-card-actions
                                        v-spacer
                                        v-btn.orange.white--text(
                                            text
                                            @click="closeDelete"
                                        ) Cancel
                                        v-btn.brand.white--text(
                                            text
                                            @click="deleteItemConfirm"
                                        ) OK

                    template(v-slot:item.actions="{ item }")
                        v-icon(
                            small
                            color="#333"
                            @click="editItem(item)"
                        ) edit
                        v-icon(
                            small
                            color="red"
                            @click="deleteItem(item)"
                        ).pl-2 delete
            v-card-text.pt-0 Workers need to be created for each task.
</template>
<script lang="js">

import VueTrix from 'vue-trix';

export default {
    meta: {
        role: 'analytics-tasks'
    },
    components: {
        VueTrix
    },
    data () {
        return {
            headers: [
                { text: 'Task Name', value: 'task_name' },
                { text: 'Schedule', value: 'schedule' },
                { text: 'Priority', value: 'priority' },
                { text: 'Depends On', value: 'depends_on' },
                { text: 'Resource Limit', value: 'resource_limit' },
                { text: 'Actions', value: 'actions', sortable: false }
            ],
            tasks: [],
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: {
                task_name: '',
                schedule: 'DAY',
                priority: 10,
                depends_on: null,
                resource_limit: null,
                event_name: '',
                worker_name: '',
                description: ''
            },
            defaultItem: {
                task_name: '',
                schedule: 'DAY',
                priority: 10,
                depends_on: null,
                resource_limit: null,
                event_name: '',
                worker_name: '',
                description: ''
            },
            dialogTitle: '',
            valid: false,
            scheduleOptions: [
                'DAY',
                'DEMAND',
                'EVENT',
                'MONTH',
                'WEEK',
            ],
            rules: {
                required: value => !!value || 'Required.',
                minLength: value => value && value.length >= 4 || 'Min 4 characters',
                duplicate: value => !this.checkTaskExists(value) || 'Task name already exists',
                notSelf: value => value !== this.editedItem.task_id || 'Task cannot depend on itself',
                isPosNumberOrEmpty: value => {
                    console.log('value:', value, typeof value);
                    if(value === null || value === undefined || value === '') {
                        return true;
                    }
                    if(Number.isNaN(value)) {
                        return 'Must be a number';
                    }
                    const num = parseInt(value, 10);
                    if(Number.isInteger(num) && num > 0) {
                        console.log('value is a positive integer');
                        return true;
                    }


                    return 'Must be empty or a positive integer greater than zero';
                }
            }
        };
    },
    methods: {
        getTasks () {
            this.socketEmit('getTasks', null, ({tasks, error}) => {
                if(tasks) {
                    this.$set(this, 'tasks', tasks);
                } else {
                    this.$set(this, 'tasks', []);
                    console.error('Error fetching tasks:', error);
                }
            });
        },
        initialize () {
            this.getTasks();
        },

        editItem (item) {
            this.editedIndex = this.tasks.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },

        deleteItem (item) {
            this.editedIndex = this.tasks.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm () {
            this.tasks.splice(this.editedIndex, 1);
            this.socketEmit('deleteTask', this.editedItem, this.closeDelete);
        },

        close () {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
                this.valid = false;
            });
        },

        closeDelete () {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save () {
            if (this.editedIndex > -1) {
                // update the task in the tasks array
                this.$set(this.tasks, this.editedIndex, this.editedItem);
            } else {
                // push new task to tasks array
                this.$set(this.tasks, this.tasks.length, this.editedItem);
            }
            this.socketEmit('saveTask', this.editedItem, this.close);
            this.close();
            this.getTasks();
        },

        checkTaskExists (name) {
            if(this.editedIndex > -1) {
                return false;
            }
            return Boolean(this.tasks.find(t => t.task_name.toLowerCase() === name.toLowerCase()));
        },

        getTaskName (taskId) {
            const task = this.tasks.find(t => t.task_id === taskId);
            return task ? task.task_name : 'Unknown';
        }
    },
    watch: {
        dialog (val) {
            val || this.close();
        },

        dialogDelete (val) {
            val || this.closeDelete();
        },
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Task' : 'Edit Task';
        },
        nameTitle () {
            return this.editedIndex === -1 ? 'Task Name' : 'Task Name (read-only)';
        }
    },
    mounted () {
        this.initialize();
    },
    created () {
        this.$store.dispatch('connectSocket');
    },
};
</script>

<style scoped lang="css">

.actions.v-btn--disabled.v-btn--text {
    background-color: #ddd !important;
}
.actions.v-btn--disabled > span {
    color: #777 !important;
    background-color: #ddd !important;
}

</style>
