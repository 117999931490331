<router>
{
    name: 'Analytics',
}
</router>


<template lang="pug">
v-container
    v-card(light, flat)
        v-row
            v-spacer
            v-col.text-center
                .text-h4 Advanced Analytics
            v-spacer
        v-row(v-if= '!hasAnalytics')
            v-col.text-center
                v-card
                    v-card-text
                        v-icon(color='brand', size='64') real_estate_agent
                        br
                        span.font-weight-bold Contact Dorsett Controls to explore your options for Advanced Analytics
                        br
                        .text-h6.brand--text Sales
                        span.font-weight-bold Email
                        br
                        a.brand--text(:href='`mailto:${sales.email}`', target='_blank') {{ sales.email }}
                        br
                        br
                        span.font-weight-bold Phone
                        br
                        a.brand--text(:href='`tel:${dorsett.number}`', target='_blank') {{ dorsett.number }}

        v-row(justify='center', v-if='hasAnalytics', )
            v-col.text-center.flex-shrink-1.flex-grow-0
                v-card(width='300', height='200', @click='navigateToSettings')
                    v-card-text
                        v-icon(color='brand', size='64') settings
                        br
                        .text-h6.brand--text Settings
                        span Settings
            v-col.text-center.flex-shrink-1.flex-grow-0
                v-card(width='300', height='200', @click='navigateToFlow', :disabled='!canShowTabs')
                    v-card-text
                        v-icon(color='brand', size='64') query_stats
                        br
                        .text-h6.brand--text Flow
                        span Analyze and Predict Flow
                        p(v-if='!canShowTabs' class='missing-settings') Missing settings. Please configure the necessary settings.
            v-col.text-center.flex-shrink-1.flex-grow-0
                v-card(width='300', height='200', :disabled='!canShowTabs')
                    v-card-text
                        v-icon(color='brand', size='64') show_chart
                        br
                        .text-h6.brand--text Power
                        span Power Usage
                        p(v-if='!canShowTabs' class='missing-settings') Missing settings. Please configure the necessary settings.
            v-col.text-center.flex-shrink-1.flex-grow-0
                v-card(width='300', height='200' :disabled='!canShowTabs')
                    v-card-text
                        v-icon(color='brand', size='64') bubble_chart
                        br
                        .text-h6.brand--text Data Analysis
                        span Advanced data analysis tools
                        p(v-if='!canShowTabs' class='missing-settings') Missing settings. Please configure the necessary settings.

</template>

<style  lang="scss" scoped>
.missing-settings {
    color: red;
    font-weight: bold;
    font-size: 0.8rem;
    margin-top: 0.2rem;
    text-align: center;
}
</style>

<script>

export default {
    data () {

        return {
            dorsett: {
                name: 'Dorsett Controls',
                address: '486 N. Patterson Ave. Suite 301',
                city: 'Winston Salem',
                state: 'NC',
                zip: '27101-4264',
                number: '1-855-DT SCADA (387-2232)',
                email: 'info@dorsettcontrols.com',
                fax: '(336) 679-8811'
            },
            training: {
                email: 'training@dorsettcontrols.com'
            },
            support: {
                email: 'support@dorsettcontrols.com'
            },
            sales: {
                email: 'sales@dorsettcontrols.com'
            },
        };

    },
    computed: {
        canShowTabs () {
            const data = this.$store.state.analyticsConfig;
            if(data && Array.isArray(data) && data.length > 0) {
                return true;
            }

            return false;
        },
        hasAnalytics () {
            const data = this.$store.state.customerData;
            if (!data) {
                return false;
            }
            const {customersConfig} = data;
            return Boolean( customersConfig  && customersConfig.hasAnalytics === 1);
        },
        loading () {
            return this.$store.state.loading;
        },
        error () {
            return this.$store.state.error;
        },
    },
    methods: {
        navigateToFlow () {
            // customer/analytics
            this.$router.push('/customer/analytics/flow')
                .catch(error => {
                    console.error(error);
                });
        },
        navigateToSettings () {
            // customer/analytics
            this.$router.push('/customer/analytics/settings')
                .then(() => {
                    console.log('Navigated to settings:');
                })
                .catch(error => {
                    console.error(error);
                });
        }
    },
    mounted () {
        console.log('Analytics Home: Mounted');
    }
};

</script>
